export default [
    {
        label: '번호',
        field: 'orderNumber',
        sort: true
    },
    {
        label: '제목',
        field: 'title',
        sort: true
    },
    {
        label: '구매자',
        field: 'email',
        sort: true
    },
    {
        label: '결제여부',
        field: 'isPayment',
        sort: true
    },
];
